<template>
  <div>
    <!-- banner -->
    <div class="banner"></div>
    <!-- 企业特色 -->
    <div class="trait">
      <div class="title">企业特色</div>
      <div class="subTitle">全场景覆盖，快速高效解决招聘问题</div>
      <div class="traitCont flex2">
        <div class="traitList" v-for="(item, index) in list" :key="index">
          <img :src="item.pic" alt="" />
          <div class="traitTitle">{{ item.title }}</div>
          <div>{{ item.cont }}</div>
        </div>
      </div>
    </div>
    <!-- 海量人才 -->
    <div class="talent">
      <div class="title">海量人才</div>
      <div class="subTitle">全场景覆盖，快速高效解决招聘问题</div>
      <div class="talentCont">
        <img src="../../assets/img/talent.png" alt="" />
        <div class="flex">
          <div
            class="talentList"
            v-for="(talentItem, talent) in talentList"
            :key="talent"
          >
            <div class="talentTitle">{{ talentItem.title }}</div>
            <div>{{ talentItem.cont }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 直播广告 -->
    <div class="advertisement">
      <div class="title">直播广告</div>
      <div class="subTitle">全场景覆盖，快速高效解决招聘问题</div>
      <div class="ad flex2">
        <div class="adList" v-for="(adItem, ad) in adList" :key="ad">
          <div class="adBanner"><img :src="adItem.pic" alt="" /></div>
          <div class="adTitle">{{ adItem.title }}</div>
          <div class="adDate">
            <i class="el-icon-time"></i>{{ adItem.date }}
          </div>
        </div>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <div class="title">新闻资讯</div>
      <div class="subTitle">全场景覆盖，快速高效解决招聘问题</div>
      <div class="newsCont flex2">
        <div
          class="newsList flex1"
          v-for="(newsItem, news) in newsList"
          :key="news"
        >
          <div class="newsLeft"><img :src="newsItem.pic" alt="" /></div>
          <div class="newsRight">
            <div class="newsTitle">{{ newsItem.title }}</div>
            <div class="newsText">{{ newsItem.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          pic: require("@/assets/img/mediaIcon1.png"),
          title: "更多的获流量手段",
          cont: "覆盖多种场景，全渠道流量引流,构建高效的人才矩阵，搭建企业自有流量池",
        },
        {
          pic: require("@/assets/img/mediaIcon2.png"),
          title: "更多的获流量手段",
          cont: "覆盖多种场景，全渠道流量引流,构建高效的人才矩阵，搭建企业自有流量池",
        },
        {
          pic: require("@/assets/img/mediaIcon3.png"),
          title: "更多的获流量手段",
          cont: "覆盖多种场景，全渠道流量引流,构建高效的人才矩阵，搭建企业自有流量池",
        },
      ],
      talentList: [
        {
          title: "企业入驻",
          cont: "1000+家企业进驻 3000+个岗位上线 19000+个就业机会",
        },
        {
          title: "人才入驻",
          cont: "覆盖全市18多个街镇，找工作及个人提供最准确，最全面的职位招聘信息",
        },
        {
          title: "简历库",
          cont: "招聘简历,海量优质简历庞大经营人才库为企业精准推荐合适人选",
        },
        {
          title: "海量人才",
          cont: "每天更新大量的人才招聘信息针对大众职位的人才网近期招聘信息平台，免费、快速找工作",
        },
      ],
      adList: [
        {
          pic: require("@/assets/img/ad1.png"),
          title: "【2022届校园招聘】电力行业 | 教育部校园招聘秋招节",
          date: "2021-10-28",
        },
        {
          pic: require("@/assets/img/ad2.png"),
          title: "【2022届校园招聘】电力行业 | 教育部校园招聘秋招节",
          date: "2021-10-28",
        },
        {
          pic: require("@/assets/img/ad3.png"),
          title: "【2022届校园招聘】电力行业 | 教育部校园招聘秋招节",
          date: "2021-10-28",
        },
      ],
      newsList: [
        {
          pic: require("@/assets/img/news1.png"),
          title: "在个人简历上营造“三大亮点”顺利赢得",
          text: "就业调查结果显示，超过60%的职场人求职主渠道是通过网络招聘和现场招聘会，也就是说，企业在招聘时，招聘人员见到求职者简历优先于本人已成为固定的模式，而工作....",
        },
        {
          pic: require("@/assets/img/news2.png"),
          title: "在个人简历上营造“三大亮点”顺利赢得",
          text: "就业调查结果显示，超过60%的职场人求职主渠道是通过网络招聘和现场招聘会，也就是说，企业在招聘时，招聘人员见到求职者简历优先于本人已成为固定的模式，而工作....",
        },
        {
          pic: require("@/assets/img/news3.png"),
          title: "在个人简历上营造“三大亮点”顺利赢得",
          text: "就业调查结果显示，超过60%的职场人求职主渠道是通过网络招聘和现场招聘会，也就是说，企业在招聘时，招聘人员见到求职者简历优先于本人已成为固定的模式，而工作....",
        },
        {
          pic: require("@/assets/img/news4.png"),
          title: "在个人简历上营造“三大亮点”顺利赢得",
          text: "就业调查结果显示，超过60%的职场人求职主渠道是通过网络招聘和现场招聘会，也就是说，企业在招聘时，招聘人员见到求职者简历优先于本人已成为固定的模式，而工作....",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 28px;
  color: #333;
  text-align: center;
}
.subTitle {
  font-size: 14px;
  color: #999;
  text-align: center;
  margin: 10px 0 30px;
}
// banner
.banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/img/media.png) center;
}
// 企业特色
.trait {
  width: 100%;
  height: 580px;
  background: url(../../assets/img/trait.png);
  padding-top: 45px;
  box-sizing: border-box;
  .traitCont {
    width: 1200px;
    margin: 0 auto;
    .traitList {
      width: 385px;
      padding: 50px 55px;
      box-sizing: border-box;
      background: white;
      font-size: 12px;
      color: #333;
      line-height: 2;
      text-align: center;
      img {
        width: 160px;
        height: 160px;
        margin-bottom: 40px;
      }
      .traitTitle {
        font-size: 28px;
      }
    }
  }
}
// 海量人才
.talent {
  width: 100%;
  padding: 45px 0 30px;
  background: white;
  box-sizing: border-box;
  .talentCont {
    width: 1200px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    .talentList {
      width: 300px;
      padding: 0 80px;
      box-sizing: border-box;
      text-align: center;
      line-height: 1.5;
      color: #999;
      font-size: 12px;
      .talentTitle {
        font-size: 16px;
        color: #333;
        margin-bottom: 10px;
      }
    }
  }
}
// 直播广告
.advertisement {
  width: 100%;
  padding: 45px 0 30px;
  background: white;
  box-sizing: border-box;
  .ad {
    width: 1200px;
    margin: 0 auto;
    .adList {
      width: 385px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
      padding-bottom: 10px;
      .adBanner {
        width: 100%;
        height: 235px;
        overflow: hidden;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
      .adTitle {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
        padding: 0 15px;
        line-height: 1.8;
      }
      .adDate {
        font-size: 12px;
        color: #999;
        padding: 0 15px;
      }
    }
  }
}
// 新闻资讯
.news {
  width: 100%;
  padding: 45px 0 30px;
  box-sizing: border-box;
  .newsCont {
    width: 1200px;
    margin: 0 auto;
    .newsList {
      width: 590px;
      background: white;
      padding: 20px;
      font-size: 14px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .newsLeft {
        width: 140px;
        height: 85px;
        overflow: hidden;
        margin-right: 15px;
        img {
          width: 100%;
        }
      }
      .newsRight {
        width: 390px;
        .newsTitle {
          width: 100%;
          font-weight: bold;
          color: #333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 5px;
        }
        .newsText {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #999;
        }
      }
    }
  }
}
</style>